import { IconButton, Loader} from 'rsuite';
import { Icon } from '@rsuite/icons';
import React from 'react';
import OfferSwiper from '../components/OfferSwiper/OfferSwiper';
import ControlWidjet from '../components/ExtraButtons/ControlWidjet';
import MainSwipper from '../components/Swippers/MainSwipper';
import axios from "axios";
import { useState, useEffect } from 'react';
import {
  imgRetake, retakeBooks,
  imgMath, mathBooks,
  imgPhysics, physicsBooks,
  imgBJD, BJDBooks,
} from '../swiperdata/swiperdata';
import { useNavigate } from 'react-router-dom';
import * as User from '@fortawesome/free-solid-svg-icons/faUser';


const tg = window.Telegram.WebApp;
const BackButton = window.Telegram.WebApp.BackButton;
window.Telegram.WebApp.disableVerticalSwipes()
window.Telegram.WebApp.expand()
window.Telegram.WebApp.setHeaderColor('#12161f')




const HomePage = () => {
  const username = tg.initDataUnsafe?.user?.first_name.toString();
  const [balance, setBalance] = useState(0);
  const [loadBal, setLoadBal] = useState(true);

  
  BackButton.isVisible = false;
  BackButton.onClick(() => window.history.go(-1))
  
  const navigate = useNavigate();

  const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
      <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
        <path d={svgPathData}></path>
      </svg>
    );
  };

  function getData(){
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/get_user_info/",
      headers:{
        'Accept': 'application/json'
      },
      data: {'user_id': tg.initDataUnsafe?.user?.id}
    })
    .then((response) => {
      const data = response.data
      setBalance(data.balance)
      setLoadBal(false);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response)
        }
    })
  };


  useEffect(()=>{
    getData();
  }, []);
  return (
    <>
    <div className='main_container' style={{height: '100%'}}>
      <div className="head">
        <div style={{width: '50%', display: 'flex', alignItems: 'center'}}>
          <IconButton
            circle
            icon={<Icon as={FaSvgIcon} faIcon={User} style={{width: '1.5em', height: '1.5em'}}/>}
            appearance='subtle'
          />
          <div style={{maxWidth: 240, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
            {username}
          </div>
        </div>

        <div className='eh-balance' onClick={() => navigate('/balance/')}>
        {!loadBal ?
          <>
            <div>{balance.toFixed(2)} ₽</div>
            <div style={{color: '#77dd77', fontSize: '12px'}}>Пополнить</div>
          </>
        :
          <div style={{width: 60, float: 'right'}}>
            <Loader
            />
          </div>
        }
        </div>
      </div>
      
      <div className='header' style={{marginTop: 12}}>
        Наши предложения
      </div>
      <div style={{height: '100%', marginLeft: 12}}>
        <OfferSwiper />
      </div>
        <ControlWidjet />
      <MainSwipper imageIdMapper={imgRetake} books={retakeBooks} setBalance={setBalance} balance={balance} title='Английский (Пересдача)' />
      {/* <MainSwipper imageIdMapper={imgMath} books={mathBooks} setBalance={setBalance} balance={balance} title='Математика'/>
      <MainSwipper imageIdMapper={imgPhysics} books={physicsBooks} setBalance={setBalance} balance={balance} title='Физика'/>
      <MainSwipper imageIdMapper={imgBJD} books={BJDBooks} setBalance={setBalance} balance={balance} title='БЖД'/> */}
    </div>
    </>
  );
}

export default HomePage;